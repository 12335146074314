<template>
  <v-container>
    <v-row>
      <v-col>
        <base-material-card
          id="invite"
          class="py-0"
          :title="$t('invite', { name: $tc('artist') })"
          icon="mdi-account-edit"
        >
          <v-card>
            <form
              v-if="tattooer"
              action
              data-vv-scope="tattooer-guest-form"
              @submit.prevent="validateForm('tattooer-guest-form')"
            >
              <v-card-text>
                <tattooers-form
                  :title="$t('new', { name: $tc('tattooer') })"
                  :tattooer="tattooer"
                  :buttons="false"
                  @validated="e1++"
                  ref="tattooer-form"
                ></tattooers-form>
                <v-row>
                  <v-col cols="12" md="3" class="py-0">
                    <label v-text="$t('emails.from')" />
                    <v-menu
                      ref="menu"
                      tabindex="13"
                      v-model="menu.from"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      min-width="290px"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          outlined
                          dense
                          v-model="computedDateFormatted"
                          color="secondary"
                          hide-details
                          :error-messages="
                            errors.collect('tattooer-guest-form.from')
                          "
                          data-vv-validate-on="blur"
                          v-validate="'required'"
                          data-vv-name="from"
                          readonly
                          v-on="on"
                        />
                      </template>

                      <v-date-picker
                        first-day-of-week="1"
                        v-model="tattooer.from"
                        color="primary"
                        no-title
                        scrollable
                        :show-current="today"
                        :min="today"
                        @input="menu.from = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3" class="py-0">
                    <v-checkbox
                      v-model="tattooer.indefinido"
                      class="mt-7"
                      hide-details
                    >
                      <template v-slot:label>
                        <div>
                          {{ $t("tattooers.invites.indef") }}
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12" md="3" class="py-0">
                    <label v-text="'Hasta'" />
                    <v-menu
                      ref="menu"
                      tabindex="13"
                      v-model="menu.to"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      min-width="290px"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          outlined
                          dense
                          :disabled="tattooer.indefinido"
                          v-model="computedToFormatted"
                          color="secondary"
                          hide-details
                          :error-messages="
                            errors.collect('tattooer-guest-form.to')
                          "
                          data-vv-validate-on="custom"
                          v-validate="tattooer.indefinido ? '' : 'required'"
                          data-vv-name="to"
                          readonly
                          v-on="on"
                        />
                      </template>

                      <v-date-picker
                        first-day-of-week="1"
                        v-model="tattooer.to"
                        color="primary"
                        data-vv-name="to"
                        no-title
                        scrollable
                        :show-current="today"
                        :min="today"
                        @input="menu.to = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row justify="end">
                  <v-btn
                    outlined
                    @click="$router.back()"
                    style="height: 25px; width: 100px"
                    elevation="0"
                    class="mt-1 mr-3"
                    >{{ $t("cancel") }}</v-btn
                  >

                  <v-btn
                    type="submit"
                    style="
                      width: 100px !important;
                      color: #363533;
                      height: 25px;
                      padding-top: 10px !important;
                    "
                    elevation="0"
                    class="mt-1 mr-3"
                    @click="action = 'save'"
                  >
                    <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
                    {{ $t("save", { name: "" }) }}
                  </v-btn>

                  <v-btn
                    v-if="false"
                    type="submit"
                    style="
                      width: 150px !important;
                      color: #363533;
                      height: 25px;
                      padding-top: 10px !important;
                    "
                    class="mt-1 mr-3"
                    elevation="0"
                    @click="action = 'send'"
                  >
                    <v-icon style="margin-right: 5px" size="14px">$send</v-icon>
                    {{ $t("save_send", { name: "" }) }}
                  </v-btn>
                </v-row>
              </v-card-actions>
            </form>
          </v-card>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";

import * as moment from "moment";

import constants from "@/constants";
export default {
  name: "TattooerInvite",
  components: {
    TattooersForm: () => import("@/components/tattooer/Form"),
  },
  mounted() {
    this.today = moment().format("YYYY-MM-DD");
  },
  data() {
    return {
      tattooer: {
        user: { role_id: constants.roles.TATTOOER_GUEST },
        indefinido: true,
        from: null,
      },
      menu: {
        from: false,
        to: false,
      },
      action: "save",
    };
  },
  computed: {
    computedDateFormatted() {
      if (!this.tattooer.from) return null;
      const [year, month, day] = this.tattooer.from.split("-");
      return `${day}/${month}/${year}`;
    },

    computedToFormatted() {
      if (!this.tattooer.to) return null;
      const [year, month, day] = this.tattooer.to.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  methods: {
    ...mapActions("tattooers", ["addTattooerGuest"]),
    async validateForm(scope) {
      let tattooer_val = true;
      this.$refs["tattooer-form"].clearErrors();
      if (this.action === "send")
        tattooer_val = await this.$refs["tattooer-form"].validateForm(
          "tattooer-form"
        );
      let form_val = await this.$validator.validateAll(scope);
      if (form_val && tattooer_val) {
        this.addTattooerGuest(this.tattooer).then((response) => {
          if (response.success) {
            this.$router.back();
            this.$alert(this.$t("save_ok"));
          } else {
            if (response.data == "too_many_users")
              this.$confirm(this.$t(response.data)).then((x) => {
                this.$router.push({
                  name: "UserSettings",
                  params: { type: "myplan" },
                });
              });
            else this.$alert(this.$t(response.data));
          }
        });
      }
    },
  },
};
</script>

<style lang="sass">
#invite
  .v-input--selection-controls__input
    margin-top: -6px

  .v-select__slot input
    position: absolute
  label
    display: block
    height: 25px
    color: var(--v-primary-base) !important
  #select_commisions

    .big .v-select__selection
      font-size: 17px !important
      min-width: 100%
</style>